<template>
  <div>

    <b-sidebar
      id="filters-sidebar"
      shadow
      right
      bg-variant="white"
      backdrop
      @hidden="handleChange(); watchQuery = true"
      @shown="watchQuery = false"
    >
      <order-filters :filters="query" /></b-sidebar>

    <b-card
      v-visibility-change="visibilityChange"
      no-body
      class="mb-0"
    >

      <table-top
        show-search
        show-add-button
        :add-button-roles="['ROLE_DISPATCHER']"
        show-filter-button

        :limit.sync="routeQuery.limit"
        :query.sync="routeQuery.query"

        @change:limit="limitChanged"
        @change:query="queryChanged"
        @click:add="addNewOrder"
        @click:filter="showAddModal = true "
      />

      <rc-overlay :show="loading">
        <orders-table
          :loading="loading"
          :query="query"
          :orders="orders"
        />
      </rc-overlay>

      <table-bottom
        :query="query"
        :total="total"
      />
    </b-card>
  </div>
</template>

<script>
import { BCard, BSidebar } from 'bootstrap-vue'
import TableTop from '@/layouts/components/table/TableTop.vue'
import TableBottom from '@/layouts/components/table/TableBottom.vue'
import {
  cloneNested, empty, parseRequestError, transformDatetimeToHumanReadablePretty,
} from '@/helpers/helpers'
import RcOverlay from '@/layouts/components/rc/RcOverlay.vue'
import OrdersTable from '@/views/orders/OrderList/OrdersTable.vue'
import OrderFilters from '@/views/orders/OrderList/OrderFilters.vue'

export default {
  components: {
    OrderFilters,
    OrdersTable,
    TableBottom,
    TableTop,
    RcOverlay,

    BCard,
    BSidebar,
  },
  data() {
    return {
      showAddModal: false,
      loading: false,
      watchQuery: true,

      total: 0,
      query: {
        sortBy: 'transportAt',
        isSortDirDesc: false,
        query: '',
        limit: 10,
        page: 1,
        transportAtFrom: null,
        transportAtTo: null,
        statuses: [],
      },
    }
  },
  computed: {
    orders() {
      return this.$store.state.order.orders
    },
    routeQuery() {
      const routeQuery = cloneNested(this.$route.query)

      if (routeQuery.isSortDirDesc) {
        routeQuery.isSortDirDesc = routeQuery.isSortDirDesc === 'true'
      }

      if (typeof routeQuery.statuses === 'string') {
        routeQuery.statuses = [routeQuery.statuses]
      }

      if (typeof routeQuery.limit === 'string') {
        routeQuery.limit = parseInt(routeQuery.limit, 10)
      }

      if (typeof routeQuery.page === 'string') {
        routeQuery.page = parseInt(routeQuery.page, 10)
      }

      return routeQuery
    },
  },
  watch: {
    query: {
      handler() {
        if (this.watchQuery) {
          this.handleChange()
        }
      },
      deep: true,
    },
  },
  created() {
    if (!empty(this.routeQuery)) {
      this.query = { ...this.query, ...this.routeQuery }
    } else {
      this.$store.dispatch('filter/fetchFilters', this.$route.name)
        .then(filters => {
          const query = filters
          query.page = 1
          this.query = query
        })
    }
  },
  methods: {
    transformDatetimeToHumanReadablePretty,
    refreshData() {
      this.loading = true
      const query = {
        q: this.query.query,
        sortField: this.query.sortBy,
        sortDir: this.query.isSortDirDesc ? 'desc' : 'asc',
        limit: this.query.limit,
        page: this.query.page,
        transportAtFrom: this.query.transportAtFrom ? this.query.transportAtFrom : null,
        transportAtTo: this.query.transportAtTo ? this.query.transportAtTo : null,
        statuses: !empty(this.query.statuses) ? this.query.statuses : null,
      }

      Promise.all([this.$store.dispatch('order/fetchOrders', query)])
        .then(res => {
          if (this.query.page > 1 && res[0].data.items.length === 0) {
            this.query.page = 1
          }
          this.total = res[0].data.total
          this.loading = false
        })
        .catch(err => parseRequestError(err))
    },
    deleteTeam(id) {
      return id
    },
    viewTeam(id) {
      this.$router.push(`/team/view/${id}`)
    },
    limitChanged(newLimit) {
      this.query.limit = newLimit
    },
    queryChanged(newQuery) {
      this.$nextTick(() => {
        this.query.query = newQuery
      })
    },
    addNewOrder() {
      this.$store.dispatch('order/addOrder', {})
        .then(res => this.$router.push(`/order/edit/${res.data.id}`))
        .catch(err => parseRequestError(err))
    },
    handleChange() {
      const query = this.filterQuery()

      if (JSON.stringify(this.orderObject(this.routeQuery)) !== JSON.stringify(this.orderObject(query))) {
        if (!query.page) {
          query.page = 1
        }

        this.$router.push({
          query,
        })

        this.$store.dispatch('filter/setFilters', { routeName: this.$route.name, filters: query })
      }

      this.refreshData()
    },
    filterQuery() {
      const query = {}
      Object.keys(this.query)
        .forEach(key => {
          if (!empty(this.query[key])) {
            query[key] = this.query[key]
          }
        })
      return query
    },
    orderObject(unordered) {
      return Object.keys(unordered)
        .sort()
        .reduce(
          (obj, key) => {
            // eslint-disable-next-line no-param-reassign
            obj[key] = unordered[key]
            return obj
          },
          {},
        )
    },
    visibilityChange(evt, hidden) {
      if (hidden) return
      this.refreshData()
    },
  },
}
</script>

<style lang="scss">

.actions-column {
  width: 1rem;
}
</style>
