<template>
  <b-table
    ref="refUserListTable"
    responsive="md"
    class="position-relative"
    :items="orders"
    :fields="tableColumns"
    primary-key="id"
    show-empty
    :empty-text="loading ? $t('shared.loading') : $t('shared.no-data') "
    :sort-by.sync="query.sortBy"
    :sort-desc.sync="query.isSortDirDesc"
  >

    <!-- Column: typeColor -->
    <template #cell(typeColor)="data">
      <div
        v-if="['cancelled', 'draft'].includes(data.item.status)"
        class="w-100 h-100 bg-transparent"
      />
      <div
        v-else-if="['specialized_transport'].includes(data.item.type)"
        class="w-100 h-100 bg-danger"
      />
      <div
        v-else-if="['basic_transport', 'sanitary_transport'].includes(data.item.type)"
        class="w-100 h-100 bg-success"
      />
    </template>

    <!-- Column: fullNumber -->
    <template #cell(fullNumber)="data">
      <div class="text-nowrap text-center">

        <b-link
          v-if="data.item.fullNumber"
          :to="{ name: 'order-view', params: { id: data.item.id } }"
          class="font-medium-3"
          :class="{'line-through': data.item.status === 'cancelled'}"
        >
          {{ data.item.fullNumber }}
        </b-link>
        <b-link
          v-else-if="data.item.type"
          :to="{ name: 'order-edit', params: { id: data.item.id } }"
          class="font-medium-3"
        >
          {{ $t('order.types.' + data.item.type) }}
        </b-link>
        <br>
        <b-badge
          variant="light-secondary"
          class="font-medium-1"
          :style="{'background-color': getStatusColor(data.item.status)}"
        >
          {{ $t('order.status.' + data.item.status) }}
        </b-badge>
      </div>

    </template>

    <!-- Column: icons -->
    <template #cell(icons)="data">

      <div class="text-nowrap">
        <b-avatar
          v-if="data.item.prioritySignals"
          v-b-tooltip.hover.top="$t('order.priority-signals.true')"
          size="30"
          variant="primary"
          class="m-25"
        >
          <feather-icon
            icon="LoaderIcon"
            class="font-medium-5"
          />
        </b-avatar>
        <b-avatar
          v-if="data.item.covidPositive"
          v-b-tooltip.hover.top="$t('order.label.covid')"
          size="30"
          variant="warning"
          class="m-25"
        >
          <feather-icon
            icon="AlertTriangleIcon"
            class="font-medium-5"
          />
        </b-avatar>
        <b-avatar
          v-if="data.item.private"
          v-b-tooltip.hover.top="$t('order.label.private-order')"
          size="30"
          variant="success"
          class="m-25"
        >
          <feather-icon
            icon="DollarSignIcon"
            class="font-medium-5"
          />
        </b-avatar>
        <b-avatar
          v-if="data.item.returnTransport"
          v-b-tooltip.hover.top="$t('order.label.base-order-info.return-transport')"
          size="30"
          class="m-25"
        >
          <feather-icon
            icon="RepeatIcon"
            class="font-medium-5"
          />
        </b-avatar>
      </div>

    </template>

    <!-- Column: pickupAddress -->
    <template #cell(pickupAddress)="data">

      <div v-if="data.item.pickupAddress">
        <span v-if="data.item.pickupAddress.alias">
          {{ data.item.pickupAddress.alias }}<br>
          <small>{{ data.item.pickupAddress.name }}</small>
        </span>
        <span v-else-if="data.item.pickupAddress.id">
          <span v-if="data.item.pickupAddress.name">{{ data.item.pickupAddress.name }}<br></span>
          <small>{{ data.item.pickupAddress.city }}, {{ data.item.pickupAddress.address }}</small>
        </span>
      </div>

    </template>

    <!-- Column: deliveryAddress -->
    <template #cell(deliveryAddress)="data">

      <div v-if="data.item.deliveryAddress">
        <span v-if="data.item.deliveryAddress.alias">
          {{ data.item.deliveryAddress.alias }}<br>
          <small>{{ data.item.deliveryAddress.name }}</small>
        </span>
        <span v-else-if="data.item.deliveryAddress.id">
          <span v-if="data.item.deliveryAddress.name">{{ data.item.deliveryAddress.name }}<br></span>
          <small>{{ data.item.deliveryAddress.city }}, {{ data.item.deliveryAddress.address }}</small>
        </span>
      </div>

    </template>

    <!-- Column: team -->
    <template #cell(team)="data">

      <b-badge
        v-if="data.item.team"
        class="text-nowrap font-medium-1"
        variant="light-secondary"
      >
        {{ data.item.team.teamName.name }}
      </b-badge>

    </template>

    <!-- Column: transportAt -->
    <template #cell(transportAt)="data">

      <div class="">
        <b-badge
          class="text-nowrap font-medium-1"
          variant="light-secondary"
        >
          {{ transformDatetimeToHumanReadablePretty(data.item.transportAt) }}
        </b-badge>
        <timer
          :time="data.item.transportAt"
          :show="data.item.status === 'received'"
        />
      </div>

    </template>

    <!-- Column: teamEndedAt -->
    <template #cell(teamEndedAt)="data">

      <div
        v-if="data.item.teamEndedAt"
        class=""
      >
        {{ transformDatetimeToHumanReadablePretty }}
      </div>

    </template>

    <!-- Column: Actions -->
    <template #cell(actions)="data">

      <b-link
        v-if="data.item.status !== 'draft'"
        :to="{ name: 'order-view', params: { id: data.item.id } }"
        class="text-nowrap text-secondary p-25"
      >
        <feather-icon
          icon="EyeIcon"
          class="cursor-pointer"
          size="16"
        />
      </b-link>

      <b-link
        v-if="isEditButtonVisible(data.item)"
        :to="{ name: 'order-edit', params: { id: data.item.id } }"
        class="text-nowrap text-secondary p-25"
      >
        <feather-icon
          icon="EditIcon"
          class="cursor-pointer"
          size="16"
        />
      </b-link>

    </template>

  </b-table>
</template>
<script>
import { transformDatetimeToHumanReadablePretty } from '@/helpers/helpers'
import guard from '@/guard'
import {
  BAvatar, BBadge, BLink, BTable, VBTooltip,
} from 'bootstrap-vue'
import Timer from '@/views/orders/partials/Timer.vue'

export default {
  components: {
    BTable,
    BLink,
    BBadge,
    BAvatar,

    Timer,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    orders: {
      type: Array,
      default: () => {},
    },
    query: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tableColumns: [
        {
          label: '',
          key: 'typeColor',
          class: 'order-type-color-column position-relative d-none d-md-table-cell p-50',
        },
        {
          label: this.$t('order.label.full-number'),
          key: 'fullNumber',
          sortable: true,
          class: 'p-50',
        },
        {
          label: '',
          key: 'icons',
          class: 'text-center p-50',
        },
        {
          label: this.$t('order.label.pickup-address'),
          key: 'pickupAddress',
          sortable: false,
          class: 'd-none d-lg-table-cell p-50',
        },
        {
          label: this.$t('order.label.delivery-address'),
          key: 'deliveryAddress',
          sortable: false,
          class: 'd-none d-lg-table-cell p-50',
        },
        {
          label: '',
          key: 'team',
          class: 'text-center p-50',
        },
        {
          label: this.$t('order.label.transport-date'),
          key: 'transportAt',
          sortable: true,
          class: 'text-center p-50',
        },
        {
          label: this.$t('shared.actions'),
          key: 'actions',
          value: null,
          class: 'text-center p-50',
        },
      ],
    }
  },
  computed: {
    me() {
      return this.$store.state.auth.activeUser
    },
  },
  methods: {
    transformDatetimeToHumanReadablePretty,
    getStatusColor(status) {
      switch (status) {
        case 'received':
          return '#F7E7C4'
        case 'cancelled':
          return '#C6DAFB'
        default:
          return null
      }
    },
    isEditButtonVisible(order) {
      if (order.status === 'draft') {
        return true
      }

      if (guard.hasAtLeastOneRole(this.me.roles, ['ROLE_ADVANCED_DISPATCHER'])) {
        return true
      }

      const statusesWhenDispatcherCantEdit = ['cancelled', 'done']
      if (guard.hasAtLeastOneRole(this.me.roles, ['ROLE_DISPATCHER'])
          && !statusesWhenDispatcherCantEdit.includes(order.status) && !order.patientOnBoard) {
        return true
      }

      return false
    },
  },
}
</script>

<style lang="scss">
.order-type-color-column {
  width: 8px !important;
  min-width: 8px;
  padding: 0 !important;

  > div {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
  }
}
</style>
