<template>
  <div class="mt-1 mx-1">
    <div>
      <div class="mb-1">
        <h4>{{ $t('order.filters.title') }}</h4>
      </div>

      <div class="w-full">
        <div class=" mb-1">
          <label class="vs-input--label block">
            {{ $t('order.filters.label.status') }}
          </label>
          <v-select
            v-model="filters.statuses"
            multiple
            clearable
            :options="statuses"
            :close-on-select="false"
          >

            <span
              slot="selected-option"
              slot-scope="option"
            >
              {{ $t('order.status.' + option.label ) }}
            </span>

            <template #option="option">
              {{ $t('order.status.' + option.label) }}
            </template>

          </v-select>
        </div>

        <div class="mb-1">

          <label class="vs-input--label block">
            {{ $t('order.filters.label.transport-date-from') }}
          </label>

          <flat-pickr
            v-model="filters.transportAtFrom"
            class="form-control"
            :config="configDateTimePicker"
          />

        </div>

        <div class="mb-2">

          <label class="vs-input--label block">
            {{ $t('order.filters.label.transport-date-to') }}
          </label>

          <flat-pickr
            v-model="filters.transportAtTo"
            class="form-control"
            :config="configDateTimePicker"
          />
        </div>
      </div>
    </div>

    <div>
      <b-button
        v-b-toggle.filters-sidebar
        variant="primary"
        block
      >
        {{ $t('shared.button.close') }}
      </b-button>
    </div>

    <div
      v-show="flatFilters.length"
    >

      <h4 class="mt-3 mb-1">
        {{ $t('order.filters.active') }}:
      </h4>
      <b-badge
        v-for="(elem, key) in flatFilters"
        :key="key"
        class="font-medium-1 mb-50 cursor-pointer"
        variant="light-primary"
        @click="removeFilter(elem.path)"
      >
        <feather-icon
          icon="XIcon"
          class="font-medium-2 mr-50"
        />
        <i class="font-medium-1">{{ elem.label }}</i>: {{ elem.value }}
      </b-badge>

      <div class="clear-both" />
    </div>

  </div>
</template>
<script>
import { Polish as PolishLocale } from 'flatpickr/dist/l10n/pl'
import { empty } from '@/helpers/helpers'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.min.css'
import vSelect from 'vue-select'
import { BBadge, BButton, VBToggle } from 'bootstrap-vue'

export default {
  name: 'OrderFilters',
  components: {
    flatPickr,
    vSelect,

    BButton,
    BBadge,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    showFilter: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      originalFilters: {},
      configDateTimePicker: {
        locale: PolishLocale,
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        defaultHour: 6,
        disableMobile: true,
      },
    }
  },
  computed: {
    statuses() {
      return this.$store.getters['order/getStatuses']
    },
    flatFilters() {
      const flat = []

      if (this.filters.transportAtFrom) {
        flat.push({
          path: ['transportAtFrom'],
          label: this.$t('order.filters.label.transport-date-from'),
          value: this.filters.transportAtFrom,
        })
      }

      if (this.filters.transportAtTo) {
        flat.push({
          path: ['transportAtTo'],
          label: this.$t('order.filters.label.transport-date-to'),
          value: this.filters.transportAtTo,
        })
      }

      if (!empty(this.filters.statuses)) {
        this.filters.statuses.forEach((status, key) => {
          flat.push({
            path: ['statuses', key],
            label: this.$t('order.filters.label.status'),
            value: this.$t(`order.status.${status}`),
          })
        })
      }

      return flat
    },
  },
  methods: {
    empty,
    removeFilter(path) {
      const { filters } = this

      if (path.length === 1) {
        filters[path[0]] = ''
      }
      if (path.length === 2) {
        const newArray = []
        filters[path[0]].forEach((filter, key) => {
          if (key !== path[1]) {
            newArray.push(filter)
          }
        })
        filters[path[0]] = newArray
      }

      this.filters = filters
    },
  },
}
</script>
