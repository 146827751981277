<template>
  <div v-if="visible">
    <b-badge
      :variant="color"
      class="text-nowra font-medium-1p"
    >
      <span>
        {{ hours }} : {{ sprintf('%02d', minutes) }} : {{ sprintf('%02d', seconds) }}
      </span>
    </b-badge>
  </div>
</template>

<script>

import moment from 'moment'
import { sprintf } from 'printj'
import { BBadge } from 'bootstrap-vue'

export default {
  components: {
    BBadge,
  },
  props: {
    time: {
      type: String,
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      interval: null,
      diff: 12 * 60 * 60,
    }
  },
  computed: {
    visible() {
      return this.show && this.hours < 12
    },
    moment() {
      return moment(this.time)
    },
    hours() {
      let hours = Math.floor(this.diff / 3600)
      if (hours < 0) { hours = (hours * -1) - 1 }
      return hours
    },
    minutes() {
      let minutes = Math.round((this.diff % 3600) / 60)
      if (minutes < 0) { minutes = (minutes * -1) - 1 }
      return minutes
    },
    seconds() {
      let seconds = Math.round(this.diff % 60)
      if (seconds < 0) { seconds *= -1 }
      return seconds
    },
    color() {
      return this.diff > 0 ? 'success' : 'danger'
    },
  },
  mounted() {
    this.interval = setInterval(() => {
      this.diff = this.getDiff()
    }, 100)
  },
  methods: {
    sprintf,
    getDiff() {
      return this.moment.diff(moment(), 'seconds')
    },
  },
}
</script>
